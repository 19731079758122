<template>
    <HomeButton />
    <div class="dash">
        <h1>Menu</h1>
        <nav>
            <router-link class="nav-link" to="/dashboard/form">Form Example</router-link><br>
            <router-link class="nav-link" to="/dashboard/DBBrowser">DB-Browser</router-link><br>
            <router-link class="nav-link" to="/dashboard/admin">admin</router-link><br>
            <router-link class="nav-link" to="/dashboard/jelly">media</router-link><br>
            <router-link class="nav-link" to="/dashboard/backup">backup</router-link><br>
            <router-link class="nav-link" to="/dashboard/shortlink">shortlink</router-link><br>
        </nav>
        <router-view>

        </router-view>
    </div>
</template>

<script>
 // components 
//import HomeButton from '@/components/HomeButton.vue';

// export default
export default {
  components: {
  }
};
</script>

<style>
.dash {
    margin: 2%;
    padding: 2%;
    background-color: #111;
}
.nav-link {
    display: inline-block;
    padding: 10px 15px;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
    background-color: #007BFF;
    color: #ffffff;
}

.nav-link:active {
    background-color: #0056b3;
    color: #ffffff;
}

</style>
